////////////////////////////////////////////////////////////////////////////////////////////////////

@import "mixins";
@import "typography";

////////////////////////////////////////////////////////////////////////////////////////////////////

// LOGIN INPUTS.
%nano-login-inputs {
  font-family: $nano-primary-font-family;
  font-size: 15px;
  font-weight: $nano-font-weight-medium;
  color: $nano-secondary-color;
  text-align: left;

  width: 100%;
  height: 60px;
  outline: none;
  margin: 0;
  padding: 10px 20px 10px 47.5px;
  overflow: hidden;
  background: $nano-inputs-background-color;
  border-width: 1px;
  border-style: solid;
  border-color: $nano-inputs-border-color;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px,
              rgb(0 0 0 / 5%) 0px 3px 6px;

  @include vendor-prefix ('appearance', 'none');
  @include vendor-prefix ('border-radius', '30px');
  @include vendor-prefix ('transition', 'all .35s ease');

  &:disabled {
    opacity: .375;
    pointer-events: none;
  }

  &:focus, &:focus-visible {
    padding-left: 57.5px;
    border-color: $nano-primary-color;
  }

  &::placeholder { color: $nano-inputs-placeholder-font-color; }

  @media (min-width: $nano-lg-breakpoint) { min-width: 425px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////
