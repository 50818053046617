////////////////////////////////////////////////////////////////////////////////////////////////////

@import url('https://cdn.materialdesignicons.com/6.5.95/css/materialdesignicons.min.css');

////////////////////////////////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: 'Roobert Regular';
  src: url("fonts/Roobert-Regular.otf");
}

@font-face {
  font-family: 'Roobert Medium';
  src: url("fonts/Roobert-Medium.otf");
}

@font-face {
  font-family: 'Roobert SemiBold';
  src: url("fonts/Roobert-SemiBold.otf");
}

@font-face {
  font-family: 'Roobert Bold';
  src: url("fonts/Roobert-Bold.otf");
}

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-primary-font-family:                                            'Roobert Regular', sans-serif;
$nano-mdi-icons-font-family:                                                'Material Design Icons';

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-primary-font-family-regular:                                    'Roobert Regular', sans-serif;
$nano-primary-font-family-medium:                                      'Roobert Medium', sans-serif;
$nano-primary-font-family-semibold:                                  'Roobert SemiBold', sans-serif;
$nano-primary-font-family-bold:                                          'Roobert Bold', sans-serif;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-font-size-base:                                                                          1rem;
$nano-font-size-xs:                                                                         .625rem;
$nano-font-size-sm:                                                                          .75rem;
$nano-font-size-md:                                                                         .875rem;
$nano-font-size-lg:                                                                        1.125rem;
$nano-font-size-xl:                                                                         1.25rem;
$nano-font-size-xxl:                                                                         1.5rem;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-font-weight-light:                                                                        300;
$nano-font-weight-regular:                                                                      400;
$nano-font-weight-medium:                                                                       500;
$nano-font-weight-semibold:                                                                     600;
$nano-font-weight-bold:                                                                         700;

////////////////////////////////////////////////////////////////////////////////////////////////////
