////////////////////////////////////////////////////////////////////////////////////////////////////

// LAYOUT GENERAL SETTINGS.
.loginLayout {
  height: 100vh;

  @media (min-width: $nano-lg-breakpoint) {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

}

// NANO LOGO - ORANGE COL.
.loginLayout .orangeCol {
  position: relative;
  overflow: hidden;
  background: $nano-primary-color;

  img {
    width: auto;
    height: 100%;

    @include position (absolute, $bottom: -150px, $right: -350px);
  }

  @media (max-width: $nano-lg-breakpoint) { display: none; }

}

// LOGIN FORM COL.
.loginLayout .formCol {
  @media (max-width: $nano-lg-breakpoint) {
    width: 90%;

    @include position (absolute, $top: 50%, $left: 50%);
    @include vendor-prefix ('transform', 'translate(-50%, -50%)');
  }

  @media (min-width: $nano-lg-breakpoint) {
    display: table;
    width: auto;
    margin: auto;
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.adminLogoContainer {
  margin: 0 0 75px 0;
  text-align: center;

  // NANO LOGO.
  img {
    width: auto;
    height: 30px;
  }

  // 'ADMIN PORTAL' CAPTION.
  b {
    display: inline-block;
    margin: 0 0 0 6.5px;
    font-family: $nano-primary-font-family-bold;
    font-size: $nano-font-size-xl;
    color: $nano-secondary-color;

    @include vendor-prefix ('transform', 'translateY(-6.5px)');

    span {
      font-family: $nano-primary-font-family-regular;
      font-weight: $nano-font-weight-light;
    }

  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// INPUTS.
.loginInputsContainer {
  position: relative;
  margin: 0;

  // ICONS.
  i {
    font-size: $nano-font-size-xxl;
    color: $nano-secondary-color;
    line-height: 1rem;

    @include position (absolute, $top: 50%, $left: 17.5px);
    @include vendor-prefix ('transform', 'translateY(-42.5%)');
  }

  // 'LOGIN' & 'PASSWORD' INPUTS.
  .loginEmailInput,
  .loginPasswordInput { @extend %nano-login-inputs; }

  &.last { margin: $nano-space-md 0 0 0; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// ERROR MESSAGES.
.errorMessage {
  margin: $nano-space-xs 0 0 0;

  span {
    font-family: $nano-primary-font-family-regular;
    font-size: .875rem;
    color: $nano-error-accent-color;
    line-height: .875rem;

    // ALERT ICON.
    i {
      font-size: 1.125rem;
      color: $nano-error-accent-color;
      display: inline-block;
      margin: 0 $nano-space-xxs 0 0;
      padding: 0 0 0 20.5px;

      @include vendor-prefix ('transform', 'translateY(1.5px)');
    }

  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// 'FORGOT YOUR PASSWORD' ANCHOR.
.forgotPasswordAnchor {
  display: table;
  margin: $nano-space-md auto $nano-space-xl auto;
  font-family: $nano-primary-font-family-semibold;
  font-size: $nano-font-size-md;
  color: $nano-forgot-password-font-color;
  text-decoration: none;

  &:active, &:focus, &:hover {
    color: $nano-forgot-password-font-color;
    text-decoration: underline;
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// LOGIN BUTTON.
.loginBtn { @extend %nano-login-btn; }

////////////////////////////////////////////////////////////////////////////////////////////////////
