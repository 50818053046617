////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-admin-header-desktop-width:                                                              100%;
$nano-admin-header-desktop-height:                                                             65px;

$nano-logo-desktop-height:                                                                     30px;
$nano-logo-mobile-height:                                                                      25px;

$nano-logout-btn-desktop-width:                                                                50px;
$nano-logout-btn-mobile-width:                                                               42.5px;
$nano-logout-btn-desktop-height:                                                               65px;
$nano-logout-btn-mobile-height:                                                                65px;

////////////////////////////////////////////////////////////////////////////////////////////////////

.adminHeader {
  width: $nano-admin-header-desktop-width;
  height: $nano-admin-header-desktop-height;
  background: $nano-admin-header-background-color;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 10%);
  margin: 0 0 50px 0;
  padding: 0;
}

.adminHeaderContainer {
  height: $nano-admin-header-desktop-height;
  margin: 0 auto;
  padding: 0 25px;

  @media (min-width: $nano-md-breakpoint) { padding: 0 50px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// NANO LOGO.
.adminHeaderLogo {
  width: auto;
  height: $nano-logo-mobile-height;

  @include vendor-prefix ('transform', 'translateY(20px)');

  @media (min-width: $nano-md-breakpoint) {
    height: $nano-logo-desktop-height;

    @include vendor-prefix ('transform', 'translateY(17.5px)');
  }

}

// 'ADMIN PORTAL' CAPTION.
.adminPortalCaption {
  display: none;
  margin: 0 0 0 6.5px;
  font-family: $nano-primary-font-family-bold;
  font-size: $nano-font-size-xl;
  color: $nano-secondary-color;

  @include vendor-prefix ('transform', 'translateY(10px)');

  span {
    font-family: $nano-primary-font-family-regular;
    font-weight: $nano-font-weight-light;
  }

  @media (min-width: $nano-md-breakpoint) { display: inline-block; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// LOGOUT BTN.
.adminHeaderLogout {
  width: $nano-logout-btn-mobile-width;
  height: $nano-logout-btn-mobile-height;
  float: right;
  background: $nano-primary-color;
  outline: none;
  text-align: center;
  line-height: 65px;

  @include vendor-prefix ('transition', 'all .25s ease');

  // LOGOUT ICON.
  i {
    font-size: $nano-font-size-base;
    color: $nano-tertiary-color;

    @media (min-width: $nano-md-breakpoint) { font-size: $nano-font-size-xl; }

  }

  &:active, &:focus, &:hover { background: darken($nano-primary-color, 5); }

  @media (min-width: $nano-md-breakpoint) {
    width: $nano-logout-btn-desktop-width;
    height: $nano-logout-btn-desktop-height;
    line-height: 62.5px;
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////
