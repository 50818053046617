////////////////////////////////////////////////////////////////////////////////////////////////////

// NANO BRANDING COLORS.
$nano-primary-color:                                                                        #F5A64F;
$nano-secondary-color:                                                                      #3C4858;
$nano-tertiary-color:                                                                       #FFFFFF;

////////////////////////////////////////////////////////////////////////////////////////////////////

// HEADER RELATED COLORS.
$nano-admin-header-background-color:                                                        #FFFFFF;

////////////////////////////////////////////////////////////////////////////////////////////////////

// SELECTION RELATED COLORS.
$nano-selection-font-color:                                                                 #FFFFFF;
$nano-selection-color:                                                                      #F5A64F;

////////////////////////////////////////////////////////////////////////////////////////////////////

// INPUT RELATED COLORS.
$nano-inputs-border-color:                                                                  #DDDDDD;
$nano-inputs-background-color:                                                              #FFFFFF;
$nano-inputs-placeholder-font-color:                                                        #AAAAAA;
$nano-error-accent-color:                                                                   #DF1B41;

////////////////////////////////////////////////////////////////////////////////////////////////////

// BUTTON RELATED COLORS.
$nano-login-btn-font-color:                                                                 #FFFFFF;
$nano-login-btn-border-color:                                                               #DDDDDD;
$nano-login-btn-background-color:                                                           #F5A64F;
$nano-login-btn-disabled-font-color:                                                        #6E6E6E;
$nano-login-btn-disabled-border-color:                                                      #DDDDDD;
$nano-login-btn-disabled-background-color:                                                  #F2F2F2;

$nano-forgot-password-font-color:                                                           #3C4858;

////////////////////////////////////////////////////////////////////////////////////////////////////
