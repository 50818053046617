.spinnerNANO {
  margin: 100px auto;
  width: 45px;
  height: 45px;
  position: relative;
  background-image: url('https://nano-home.github.io/nano/assets/brand/logo-F5A64F.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  -webkit-animation: nano-bounce-rotate 2.65s infinite ease-in-out;
  animation: nano-bounce-rotate 2.65s infinite ease-in-out;
}

@-webkit-keyframes nano-bounce-rotate {
  0%, 100% {transform: scale(.05) rotate(0);}
  50%  {transform: scale(1) rotate(540deg);}
}

@keyframes nano-bounce-rotate {
  0%, 100% {transform: scale(.05) rotate(0);}
  50%  {transform: scale(1) rotate(540deg);}
}
