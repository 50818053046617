////////////////////////////////////////////////////////////////////////////////////////////////////

// VENDOR PREFIXES.
@mixin vendor-prefix ($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// OFFSET POSITIONING.
@mixin position ($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

////////////////////////////////////////////////////////////////////////////////////////////////////
