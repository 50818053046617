////////////////////////////////////////////////////////////////////////////////////////////////////

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

html, body {
  width: 100%;
  height: 100%;
  // background: $nano-body-background-color;
  margin: 0;
  padding: 0;
  // font-family: $nano-primary-font-family;
  color: $nano-secondary-color;
}

h1, h2, h3 {
  // font-family: $nano-heading-font-family;
  color: $nano-secondary-color;
}

h4, h5, h6 {
  // font-family: $nano-sub-heading-font-family;
  color: $nano-secondary-color;
}

::selection {
  text-shadow: none;
  color: $nano-selection-font-color;
  background: $nano-selection-color;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

////////////////////////////////////////////////////////////////////////////////////////////////////
