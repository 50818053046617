////////////////////////////////////////////////////////////////////////////////////////////////////

@import "colors";
@import "mixins";
@import "spacing";
@import "typography";

////////////////////////////////////////////////////////////////////////////////////////////////////

// LOGIN BUTTON.
%nano-login-btn {
  cursor: pointer;
  position: relative;
  display: table;
  width: auto;
  min-width: 125px;
  height: 60px;
  outline: 1px solid transparent;
  border: 1px solid $nano-login-btn-border-color;
  margin: 0 auto;
  padding: $nano-space-sm $nano-space-md;
  background: $nano-login-btn-background-color;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);

  font-family: $nano-primary-font-family-bold;
  font-size: $nano-font-size-base;
  font-weight: $nano-font-weight-semibold;
  color: $nano-login-btn-font-color;
  text-decoration: none;
  text-align: center;

  @include vendor-prefix ('appearance', 'none');
  @include vendor-prefix ('border-radius', '30px');
  @include vendor-prefix ('transition', 'all .25s ease');

  &:focus-visible { outline: 1px solid $nano-primary-color; }

  &:disabled, &[disabled] {
    cursor: not-allowed;
    color: $nano-login-btn-disabled-font-color !important;
    background: $nano-login-btn-disabled-background-color !important;
    border: 1px solid $nano-login-btn-disabled-border-color;
  }

  &:active, &:focus, &:hover { background: darken($nano-login-btn-background-color, 7.5); }

}

////////////////////////////////////////////////////////////////////////////////////////////////////
